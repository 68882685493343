<template>
  <b-card-actions
    action-collapse
    ref="refreshCard"
    title="Daftar Kas"
    action-refresh
    @refresh="refreshStop('refreshCard')"
  >
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-tambah
          variant="primary"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
        <b-modal
          id="modal-tambah"
          cancel-variant="secondary"
          ok-title="Add"
          cancel-title="Batal"
          centered
          title="Form Add"
        >
          <b-form>
            <b-form-group>
              <label for="jabatan">Jabatan:</label>
              <b-form-input id="jabatan" type="jabatan" placeholder="Nama Jabatan" />
            </b-form-group>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <!-- <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template> -->

          <template #cell(actions)="row">
            <b-button
              title="'Detail'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-secondary"
            >
            <feather-icon icon="ListIcon"/>
            </b-button>
            <b-button size="sm" @click="row.toggleDetails" class="mr-1">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details 2
            </b-button>
            <b-button
            title="'Ubah'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-info"
            >
            <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button
            title="'Hapus'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-danger"
            >
            <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,VBTooltip,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "Id",
        },
        // {
        //   key: 'avatar', label: 'Avatar',
        // },
        // { key: 'full_name', label: 'Full Name', sortable: true },
        { key: "jabatan", label: "Jabatan", sortable: true },
        // 'email',
        // 'city',
        // 'start_date',
        // 'salary',
        // 'age',
        // 'experience',
        // { key: 'status', label: 'Status', sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [
        {
          id: 1,
          nomor: "12414/BOP/23123",
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/10-small.png"),
          full_name: "Korrie O'Crevy",
          jabatan: "Owner",
          email: "kocrevy0@thetimes.co.uk",
          city: "Krasnosilka",
          start_date: "09/23/2016",
          salary: "$23896.35",
          age: "61",
          experience: "1 Year",
          status: 2,
        },
        {
          id: 2,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/1-small.png"),
          full_name: "Bailie Coulman",
          jabatan: "General Manager",
          email: "bcoulman1@yolasite.com",
          city: "Hinigaran",
          start_date: "05/20/2018",
          salary: "$13633.69",
          age: "63",
          experience: "3 Years",
          status: 2,
        },
        {
          id: 3,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/9-small.png"),
          full_name: "Stella Ganderton",
          jabatan: "Finance",
          email: "sganderton2@tuttocitta.it",
          city: "Golcowa",
          start_date: "03/24/2018",
          salary: "$13076.28",
          age: "66",
          experience: "6 Years",
          status: 5,
        },
        {
          id: 4,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/3-small.png"),
          full_name: "Dorolice Crossman",
          jabatan: "Kepala Gudang",
          email: "dcrossman3@google.co.jp",
          city: "Paquera",
          start_date: "12/03/2017",
          salary: "$12336.17",
          age: "22",
          experience: "2 Years",
          status: 2,
        },
        {
          id: 5,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/4-small.png"),
          full_name: "Harmonia Nisius",
          jabatan: "Admin Gudang",
          email: "hnisius4@gnu.org",
          city: "Lucan",
          start_date: "08/25/2017",
          salary: "$10909.52",
          age: "33",
          experience: "3 Years",
          status: 2,
        },
        {
          id: 6,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/5-small.png"),
          full_name: "Genevra Honeywood",
          jabatan: "Driver",
          email: "ghoneywood5@narod.ru",
          city: "Maofan",
          start_date: "06/01/2017",
          salary: "$17803.80",
          age: "61",
          experience: "1 Year",
          status: 1,
        },
        {
          id: 7,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/7-small.png"),
          full_name: "Eileen Diehn",
          jabatan: "SPV",
          email: "ediehn6@163.com",
          city: "Lampuyang",
          start_date: "10/15/2017",
          salary: "$18991.67",
          age: "59",
          experience: "9 Years",
          status: 3,
        },
        {
          id: 8,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/9-small.png"),
          full_name: "Richardo Aldren",
          jabatan: "Driver",
          email: "raldren7@mtv.com",
          city: "Skoghall",
          start_date: "11/05/2016",
          salary: "$19230.13",
          age: "55",
          experience: "5 Years",
          status: 3,
        },
        {
          id: 9,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/2-small.png"),
          full_name: "Allyson Moakler",
          jabatan: "Sales",
          email: "amoakler8@shareasale.com",
          city: "Mogilany",
          start_date: "12/29/2018",
          salary: "$11677.32",
          age: "39",
          experience: "9 Years",
          status: 5,
        },
        {
          id: 10,
          // eslint-disable-next-line global-require
          avatar: require("@/assets/images/avatars/6-small.png"),
          full_name: "Merline Penhalewick",
          jabatan: "Sales",
          email: "mpenhalewick9@php.net",
          city: "Kanuma",
          start_date: "04/19/2019",
          salary: "$15939.52",
          age: "23",
          experience: "3 Years",
          status: 2,
        },
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
